// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

//Custom theme for the application
//primary color we are choosing here -- #998ac0  ||  Secondary color we are choosing here --#5a5a5a

/* For use in src/lib/core/theming/_palette.scss */
$brand-primarycolor: (
  50: #f3f1f7,
  100: #e0dcec,
  200: #ccc5e0,
  300: #b8add3,
  400: #a89cc9,
  500: #998ac0,
  600: #9182ba,
  700: #8677b2,
  800: #7c6daa,
  900: #6b5a9c,
  A100: #ffffff,
  A200: #ded3ff,
  A400: #b7a0ff,
  A700: #a486ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$brand-accentcolor: (
  50: #f3f1f7,
  100: #e0dcec,
  200: #ccc5e0,
  300: #b8add3,
  400: #a89cc9,
  500: #998ac0,
  600: #9182ba,
  700: #8677b2,
  800: #7c6daa,
  900: #6b5a9c,
  A100: #ffffff,
  A200: #ded3ff,
  A400: #b7a0ff,
  A700: #a486ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$main-app-primary: mat.m2-define-palette($brand-primarycolor);
$main-app-accent: mat.m2-define-palette($brand-accentcolor);

// The warn palette is optional (defaults to red).
$main-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$main-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $main-app-primary,
      accent: $main-app-accent,
      warn: $main-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($main-app-theme);

/* You can add global styles to this file, and also import other style files */

html,
body,
body.mat-typography {
  height: 100%;
  margin: 0;
  font-family: "Helvetica";
}
@import "./stylesheet/master.scss";
