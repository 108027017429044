@font-face {
  font-family: "Larssiet";
  src: url("./Larsseit.otf");
}
@font-face {
  font-family: "Larssiet-medium";
  src: url("./Larsseit-Medium.otf");
}

@font-face {
  font-family: "Helventica";
  src: url("./Helvetica.ttf");
}

@font-face {
  font-family: "Helventica-Bold";
  src: url("./Helvetica-Bold.ttf");
}
