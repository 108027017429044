//-common color
//$body-color: #f5f3f9;
$body-color:#F5F3F9;
//$body-color:#fafafa;
$white-color: #ffffff;

//- brand inital color
$brand-primary-color: #998ac0;
$brand-accent-color: #5a5a5a;

//heading colors
$brand-primary-heading: #2d2d2d;
$brand-accent-heading: #998ac0;

//Button colors
$button-primary-color: #fd6b00;
$button-accent-color: #998ac0;
$button-accent-color-light: #c37bc4;
$green-color: #5eb957;

$light-pink: #fdf8f5;
$light-blue: #f5f1ff;
$border-light-grey: #dfdfdf;
$grey-black: #2c2c2c;

$grey-dark: #7e7e7e;
$grey-lighter: #9f9f9f;
$grey-input-lighter: #bababa;
//font sizes
